import React, { useState, useEffect } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { useTranslation } from "react-i18next";
import DocImg from "../Assets/paper.png";
import { Box, Button, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { apiBaseUrl, apiVersion } from "../config-global";

const Step6 = ({ formData, setFormData, isError, setIsError }) => {
  const { t } = useTranslation();
  const [ibanError, setIbanError] = useState(false);
  const [ibanHelperText, setIbanHelperText] = useState("");
  const [nationalIdError, setNationalIdError] = useState(false);
  const [nationalIdHelperText, setNationalIdHelperText] = useState("");
  const [ibanFileName, setIbanFileName] = useState("");
  const [nationalIdFileName, setNationalIdFileName] = useState("");

  // Styled components
  const InputWrapper = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    border: "2px solid",
    marginTop: theme.spacing(2),
    borderColor: "#00ccbb",
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
    "&:hover": {
      borderColor: "#009f94",
    },
  }));

  const HiddenInput = styled("input")({
    display: "none",
  });

  const CustomButton = styled(Button)(({ theme }) => ({
    marginTop: theme.spacing(1),
    padding: theme.spacing(1, 2),
    backgroundColor: "#00ccbb",
    "&:hover": {
      backgroundColor: "#009f94",
    },
  }));

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    console.log(name)
    if (name === "iban_image") {
      setIbanFileName(files[0].name);
    }
    if (name === "national_id_image") {
      setNationalIdFileName(files[0].name);
    }
    setFormData({ ...formData, [name]: files[0] });
  };

  const [banks, setBanks] = useState([]);
  useEffect(() => {
    async function fetchData() {
      const response = await fetch(
        `${apiBaseUrl}${apiVersion}public/cache/banks/all`
      );
      const data = await response.json();
      setBanks(data.data);
    }
    fetchData();
  }, []);

  //logic for handling IBAN and National ID validation
  const checkIbanOrId = async (name, value) => {
    // var { name, value } = e.target;
    console.log(name, value);
    if (name === "iban") {
      const pattern = /^SA\d{22}$/;
      // Validate the input value
      if (!pattern.test(value)) {
        setIbanError(true);
        setIbanHelperText('Input must start with "SA" followed by 22 digits.');
        setIsError(true);
      } else {
        setIbanError(false);
        setIbanHelperText("");
        setIsError(false);
      }
    }
    if (name === "national_id") {
      // Validate National ID to ensure it's exactly 10 digits
      if (!/^\d{10}$/.test(value)) {
        setNationalIdError(true);
        setNationalIdHelperText("National ID must be a 10-digit number.");
        setIsError(true);
      } else {
        setNationalIdError(false);
        setNationalIdHelperText("");
        setIsError(false);
      }
    }
  };

  const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
      if (timeoutId) clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  const debouncedValidateInput = debounce(checkIbanOrId, 500); // 500ms debounce

  const handleIbanIdInput = (e) => {
    var { name, value } = e.target;
    debouncedValidateInput(name, value);
  };

  return (
    <div>
      <div className="arounder-carousel-card">
        <img src={DocImg} alt="" width={100} />
        <h2>{t("doc_req")}</h2>
        <p className="text-light my-1">{t("doc_req_text")}</p>
      </div>
      {/* <h2>{t("doc_req")}</h2>
      <p className="text-light my-1">{t("doc_req_text")}</p> */}
      <FormControl fullWidth style={{ marginBottom: "1rem" }}>
        <InputLabel id="demo-simple-select-label">
          {t("select_bank")}
        </InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={formData.bank_id}
          label={t("select_bank")}
          name="bank_id"
          onChange={handleChange}
        >
          {banks.map((item) => (
            <MenuItem key={item.id} value={item.name.split("-")[0].trim()}>
              {item.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {/* <label>
        {t("iban_no")}:
        <input
          type="text"
          name="iban"
          value={formData.iban}
          onChange={handleChange}
        />
        <input type="file" name="iban_image" onChange={handleFileChange} />
      </label> */}
      <TextField
        name="iban"
        label={t("iban_no")}
        value={formData.iban}
        onChange={handleChange}
        variant="outlined"
        fullWidth
        required
        onInput={handleIbanIdInput}
        error={ibanError}
        helperText={ibanHelperText}
        inputProps={{
          maxLength: 24,
        }}
      />
      <br />
      <InputWrapper>
        <Typography variant="body1" gutterBottom>
          Click to select a file
        </Typography>
        <HiddenInput
          type="file"
          name="iban_image"
          onChange={handleFileChange}
          id="iban-upload"
        />
        <label htmlFor="iban-upload">
          <CustomButton variant="contained" color="primary" component="span">
            Upload IBAN
          </CustomButton>
        </label>
        {ibanFileName && (
          <Typography variant="body2" color="textSecondary" mt={2}>
            Selected file: {ibanFileName}
          </Typography>
        )}
      </InputWrapper>
      <br />
      <TextField
        type="number"
        name="national_id"
        label={t("national_id")}
        value={formData.national_id}
        onChange={handleChange}
        variant="outlined"
        fullWidth
        required
        onInput={handleIbanIdInput}
        error={nationalIdError}
        helperText={nationalIdHelperText}
      />
      <InputWrapper>
        <Typography variant="body1" gutterBottom>
          Click to select a file
        </Typography>
        <HiddenInput
          type="file"
          name="national_id_image"
          onChange={handleFileChange}
          id="id-upload"
        />
        <label htmlFor="id-upload">
          <CustomButton variant="contained" color="primary" component="span">
            Upload ID
          </CustomButton>
        </label>
        {nationalIdFileName && (
          <Typography variant="body2" color="textSecondary" mt={2}>
            Selected file: {nationalIdFileName}
          </Typography>
        )}
      </InputWrapper>
    </div>
  );
};

export default Step6;
