import React from "react";
// import ContactForm from "./ContactForm";
import Navbar2 from "./Navbar2";
import Footer from "./Footer";
import { useTranslation } from "react-i18next";

const Contact = () => {
  const { t } = useTranslation();
  return (
    <>
      <Navbar2 />
      <section className="contact-wrapper">
        <div className="faq-image">
          <div className="image-overlay"></div>
          <div className="faq-section-one">
            <h1>{t("get_in_touch")}</h1>
            <p>{t("contact_text")}</p>
          </div>
        </div>
        {/* <ContactForm /> */}
        <div class="contact-card">
          <iframe
            title="TawkTo"
            frameborder="0"
            aria-label="TawkTo"
            style={{ height: "500px", borderRadius: "20px" }}
            allowfullscreen=""
            width="100%"
            src="https://tawk.to/chat/657c065e70c9f2407f80089c/1i5v0hi8b"
          ></iframe>
        </div>
        <Footer />
      </section>
    </>
  );
};

export default Contact;
