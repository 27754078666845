import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
  Card,
  Stack,
  Typography,
  Avatar,
  Box,
  Divider,
  Chip,
} from "@mui/material";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import "./LeaderBoard.css";
// import { getInitials } from "../utils/get-initials";
import { getRandomDarkPastelColor } from "../utils/get-random-color";
import { isArabic } from "../utils/check-language";
import { maskName } from "../utils/essentials";
import { apiBaseUrl, apiVersion } from "../config-global";

const CustomersLeaderboard = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const userid = queryParams.get("userid"); // Get userid from query parameters

  const [leaderboardData, setLeaderboardData] = useState([]);
  const [currentUser, setCurrentUser] = useState({});
  const [first, setFirst] = useState({});
  const [second, setSecond] = useState({});
  const [third, setThird] = useState({});
  const [noRank, setNoRank] = useState(false);

  //make an API call to get the leaderboard data
  const getLeaderboardData = async () => {
    try {
      const response = await fetch(
        `${apiBaseUrl}${apiVersion}public/leaderboard/customers`
      );
      const data = await response.json();
      //modify the data to include first 10 users
      const top10 = data.data.slice(3, 10);
      const first = data.data[0];
      const second = data.data[1];
      const third = data.data[2];
      setLeaderboardData(top10);
      setFirst(first);
      setSecond(second);
      setThird(third);
      console.log("Leaderboard data: ", top10);
      console.log(first);
      return data;
    } catch (error) {
      console.error("Error fetching leaderboard data: ", error);
    }
  };

  //make API call to get current user data
  const getCurrentUserData = async () => {
    if (!userid) return;
    try {
      const response = await fetch(
        `${apiBaseUrl}${apiVersion}public/leaderboard/customers/${userid}/rank`
      );
      const data = await response.json();
      if (data.code === "200") setCurrentUser(data.data);
      else setNoRank(true);
    } catch (error) {
      console.error("Error fetching current user data: ", error);
      setNoRank(true);
    }
  };

  useEffect(() => {
    getLeaderboardData();
    getCurrentUserData();
  }, []);

  return (
    <Box
      className="leaderboard-container"
      sx={{ textAlign: "center", overflowY: "hidden" }}
    >
      <Box
        className="podium"
        sx={{
          display: "flex",
          justifyContent: "center",
          gap: 3,
          m: 3,
          color: "#009185",
        }}
      >
        {second && (
          <Box
            className="second-place"
            sx={{ textAlign: "center", justifyItems: "center" }}
          >
            <Avatar
              className="avatar"
              sx={{
                bgcolor: getRandomDarkPastelColor(),
                width: 56,
                height: 56,
                borderRadius: "1.25rem",
              }}
            >
              {/* {getInitials(second.f_name + " " + second.l_name)} */}
            </Avatar>
            <Typography
              variant="caption"
              sx={{
                mt: 1,
                fontWeight: second.uid === currentUser.uid ? "bold" : "normal",
                direction: isArabic(second.f_name) ? "rtl" : "ltr",
              }}
            >
              2.{" "}
              {second.f_name
                ? second.uid === currentUser.uid
                  ? second.f_name
                  : second.f_name[0] + "******"
                : "******"}
            </Typography>
          </Box>
        )}
        {first && (
          <Box
            className="first-place"
            sx={{ textAlign: "center", justifyItems: "center" }}
          >
            <Typography className="crown" variant="h4">
              👑
            </Typography>
            <Avatar
              className="avatar"
              sx={{
                bgcolor: getRandomDarkPastelColor(),
                width: 64,
                height: 64,
                borderRadius: "1.25rem",
              }}
            >
              {/* {getInitials(first.f_name + " " + first.l_name)} */}
            </Avatar>
            <Typography
              variant="caption"
              sx={{
                mt: 1,
                fontWeight: first.uid === currentUser.uid ? "bold" : "normal",
                direction: isArabic(first.f_name) ? "rtl" : "ltr",
              }}
            >
              1.
              {first.f_name
                ? first.uid === currentUser.uid
                  ? first.f_name
                  : (first.f_name[0] || "") + "******"
                : "******"}
            </Typography>
          </Box>
        )}
        {third && (
          <Box
            className="third-place"
            sx={{ textAlign: "center", justifyItems: "center" }}
          >
            <Avatar
              className="avatar"
              sx={{
                bgcolor: getRandomDarkPastelColor(),
                width: 52,
                height: 52,
                borderRadius: "1.25rem",
              }}
            >
              {/* {getInitials(third.f_name + " " + third.l_name)} */}
            </Avatar>
            <Typography
              variant="caption"
              sx={{
                mt: 1,
                fontWeight: third.uid === currentUser.uid ? "bold" : "normal",
                direction: isArabic(third.f_name) ? "rtl" : "ltr",
              }}
            >
              3.{" "}
              {third.f_name
                ? third.uid === currentUser.uid
                  ? third.f_name
                  : third.f_name[0] + "******"
                : "******"}
            </Typography>
          </Box>
        )}
      </Box>

      <Card
        sx={{
          width: "100%",
          height: "75vh",
          backgroundColor: "#e6faf8",
          position: "fixed",
          bottom: 0,
          left: 0,
          overflow: "hidden",
          p: 3,
        }}
        className="leaderboard-card"
      >
        <Box
          sx={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box sx={{ flex: 1, overflowY: "auto", paddingRight: 1 }}>
            {leaderboardData.map((player, index) => (
              <Stack
                key={index}
                sx={{ position: "relative" }}
                direction="row"
                gap={2}
              >
                <Typography
                  variant="overline"
                  fontWeight="bold"
                  sx={{
                    margin: "auto",
                    transform: "translateY(-8px)",
                    fontSize: "1.5rem",
                    color:
                      player.uid === currentUser.uid ? "#009185" : "#00918540",
                  }}
                >
                  {(index + 4).toString().padStart(2, "0")}
                </Typography>
                <Card
                  className="player-card"
                  sx={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    borderRadius: 6,
                    p: 1,
                    mb: 1.5,
                    mr: 1,
                    backgroundColor:
                      player.uid === currentUser.uid ? "#ffffffa5" : "#fff",
                    border:
                      player.uid === currentUser.uid
                        ? "2px solid #009185"
                        : "none",
                  }}
                >
                  <Box display="flex" alignItems="center">
                    <Avatar
                      sx={{
                        bgcolor: getRandomDarkPastelColor(),
                        borderRadius: "1rem",
                      }}
                    >
                      {/* {getInitials(player.f_name + " " + player.l_name)} */}
                    </Avatar>
                    <Box display="flex" sx={{ flexDirection: "column" }} mx={2}>
                      <Typography
                        fontWeight="bold"
                        sx={{
                          color: "#005645",
                          direction: isArabic(player.f_name) ? "rtl" : "ltr",
                        }}
                      >
                        {maskName(player.f_name)}
                      </Typography>
                      <Typography variant="caption" display="flex">
                        {player.points} points
                      </Typography>
                    </Box>
                  </Box>
                  <Box display="flex" alignItems="center">
                    <Typography
                      variant="body2"
                      color={
                        player.rank_change > 0
                          ? "#009185"
                          : player.rank_change < 0
                          ? "#7a0001"
                          : ""
                      }
                      fontWeight="bold"
                    >
                      {player.rank_change !== 0 ? player.rank_change : ""}
                    </Typography>
                    {player.rank_change > 0 ? (
                      <ArrowDropUpIcon sx={{ color: "#009185" }} />
                    ) : player.rank_change < 0 ? (
                      <ArrowDropDownIcon sx={{ color: "#7a0001" }} />
                    ) : (
                      ""
                    )}
                  </Box>
                </Card>
              </Stack>
            ))}

            {currentUser.uid &&
              ![first, second, third, ...leaderboardData]
                .map((player) => player.uid)
                .includes(currentUser.uid) && (
                <>
                  <Divider sx={{ my: 1 }}>
                    <Chip label="Your Rank" size="small" />
                  </Divider>

                  <Stack sx={{ position: "relative" }} direction="row" gap={2}>
                    <Typography
                      variant="overline"
                      fontWeight="bold"
                      sx={{
                        margin: "auto",
                        transform: "translateY(-8px)",
                        fontSize: "1.5rem",
                        color: "#009185",
                      }}
                    >
                      {currentUser.rank.toString().padStart(2, "0")}
                    </Typography>
                    <Card
                      sx={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        borderRadius: 6,
                        p: 1,
                        mb: 1.5,
                        mr: 1,
                        backgroundColor: "#ffffffa5",
                        border: "2px solid #009185",
                      }}
                    >
                      <Box display="flex" alignItems="center">
                        <Avatar
                          sx={{
                            bgcolor: getRandomDarkPastelColor(),
                            borderRadius: "1rem",
                          }}
                        >
                          {/* {getInitials(
                            currentUser.f_name + " " + currentUser.l_name
                          )} */}
                        </Avatar>
                        <Box
                          display="flex"
                          sx={{ flexDirection: "column" }}
                          mx={2}
                        >
                          <Typography fontWeight="bold">
                            {currentUser.f_name ? currentUser.f_name : "******"}
                          </Typography>
                          <Typography variant="caption" display="flex">
                            {currentUser.points} pts
                          </Typography>
                        </Box>
                      </Box>
                      <Box display="flex" alignItems="center">
                        <Typography
                          variant="body2"
                          color={currentUser.change > 0 ? "#009185" : "#7a0001"}
                          fontWeight="bold"
                        >
                          {currentUser.change}
                        </Typography>
                        {currentUser.rank_change > 0 ? (
                          <ArrowDropUpIcon sx={{ color: "#009185" }} />
                        ) : currentUser.rank_change < 0 ? (
                          <ArrowDropDownIcon sx={{ color: "#7a0001" }} />
                        ) : (
                          ""
                        )}
                      </Box>
                    </Card>
                  </Stack>
                </>
              )}

            {noRank && (
              <>
                <Divider sx={{ my: 1 }}>
                  <Chip label="Your Rank" size="small" />
                </Divider>

                <Stack sx={{ position: "relative" }} direction="row" gap={2}>
                  <Typography
                    variant="overline"
                    fontWeight="bold"
                    sx={{
                      margin: "auto",
                      transform: "translateY(-8px)",
                      fontSize: "1.5rem",
                      color: "#009185",
                    }}
                  >
                    {"00"}
                  </Typography>
                  <Card
                    sx={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      borderRadius: 6,
                      p: 1,
                      mb: 1.5,
                      mr: 1,
                      backgroundColor: "#ffffffa5",
                      border: "2px solid #009185",
                    }}
                  >
                    <Box display="flex" alignItems="center">
                      <Avatar
                        sx={{
                          bgcolor: getRandomDarkPastelColor(),
                          borderRadius: "1rem",
                        }}
                      ></Avatar>
                      <Box
                        display="flex"
                        sx={{ flexDirection: "column" }}
                        mx={2}
                      >
                        <Typography variant="caption" fontWeight="bold">
                          {"Your spot is waiting — refer more!"}
                        </Typography>
                        <Typography variant="caption" display="flex">
                          {"No pts"}
                        </Typography>
                      </Box>
                    </Box>
                  </Card>
                </Stack>
              </>
            )}
          </Box>
        </Box>
      </Card>
    </Box>
  );
};

export default CustomersLeaderboard;
