import React, { useState, useEffect } from "react";
import Logo from "../Assets/aroundU.jpg";
import "./WebFallback.css";
import GooglePlay from "../Assets/play-store-black.jpg";
import AppStore from "../Assets/app-store-black.jpg";
import IphonePlaceholder from "../Assets/iphone-15-pro-max.svg";
import { Divider, Typography } from "@mui/material";
import { useLocation } from "react-router-dom";
import { apiBaseUrl, apiVersion } from "../config-global";

const useQueryParams = () => {
  return new URLSearchParams(useLocation().search);
};

const WebFallback = () => {
  const query = useQueryParams();
  const code = query.get("code");

  const [title, setTitle] = useState("Welcome to aroundU");
  const [description, setDescription] = useState("");

  const fetchMetaData = async () => {
    if (!code) return;
    try {
      const response = await fetch(
        `${apiBaseUrl}${apiVersion}public/links/${code}`
      );
      const data = await response.json();
      setTitle(data?.data?.meta?.title || "Welcome to aroundU");
      setDescription(data?.data?.meta?.description || "");
    } catch (error) {
      console.error("Error fetching metadata: ", error);
    }
  };

  useEffect(() => {
    fetchMetaData();
  }, []);

  return (
    <div className="web-fallback-container">
      <div className="app-preview">
        <img
          src={IphonePlaceholder}
          alt="Iphone Placeholder"
          className="iphone-placeholder"
        />
          <img
            src={Logo}
            alt="App Logo"
            className="app-logo"
          />
        <div className="meta-info">
          <p className="meta-title">{title}</p>
          <p className="meta-desc">{description}</p>
        </div>
      </div>
      <div className="download-section">
        <h1>Scan to download the app</h1>
        {code ? (
          <img
            src={`https://api.qrserver.com/v1/create-qr-code/?format=svg&size=500x500&data=https://aroundu.link/${code}`}
            alt="QR Code"
            className="qr-code"
          />
        ) : (
          <img
            src="https://api.qrserver.com/v1/create-qr-code/?format=svg&size=500x500&data=https://aroundu.app"
            alt="QR Code"
            className="qr-code"
          />
        )}
        <Divider>
          <Typography variant="inline" className="or-text">
            OR
          </Typography>
        </Divider>
        <div className="store-buttons">
          <a href="https://play.google.com/store/apps/details?id=app.aroundu">
            <img src={GooglePlay} alt="Google Play" className="store-btn" />
          </a>
          <a href="https://apps.apple.com/in/app/aroundu/id6470918157">
            <img src={AppStore} alt="App Store" className="store-btn" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default WebFallback;
