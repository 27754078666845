import React from "react";
import Logo from "../Assets/aroundU.jpg";
import "./DefaultFallback.css";

const IosDefaultFallback = () => {
  return (
    <div className="promo-card">
      <div className="promo-banner">
        <img src={Logo} alt="App Banner" className="banner-image" />
      </div>
      <div className="promo-content">
        <h4>AROUNDU</h4>
        <h2>Welcome to aroundU</h2>
        <p>
          Your hub for endless exploration and meaningful connections. Embrace
          the boundless possibilities that...
        </p>
        <p className="small-text">See this content immediately after install</p>
        <div className="promo-footer">
          <img src={Logo} alt="App Logo" className="default-app-logo" />
          <button
            className="default-get-app-button"
            onClick={() =>
              (window.location.href =
                "https://apps.apple.com/in/app/aroundu/id6470918157")
            }
          >
            Get The App
          </button>
        </div>
      </div>
    </div>
  );
};

export default IosDefaultFallback;
