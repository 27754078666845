import React from "react";
import "./IosFallback.css";
import Logo from "../Assets/aroundU.jpg";
import FallbackImg from "../Assets/app-store-fallback.png";
import { useLocation } from "react-router-dom";
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

const useQueryParams = () => {
  return new URLSearchParams(useLocation().search);
};

const IosFallback = () => {
  const query = useQueryParams();
  const referral = query.get("code");

  const copied = (msg) => {
    toast.success(msg, {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
    });
  }

  // Copy referral code to clipboard and redirect to app store
  const handleCopyAndRedirect = () => {
    if (referral) {
      navigator.clipboard
        .writeText(referral)
        .then(() => {
          copied('Referral code copied!');
            setTimeout(() => {
            window.location.href = "https://apps.apple.com/in/app/aroundu/id6470918157"; // Redirect after copying
            }, 1000);
        })
        .catch((err) => {
          console.error("Failed to copy referral code: ", err);
        });
    } else {
      copied("No referral code found.");
      window.location.href = "https://apps.apple.com/in/app/aroundu/id6470918157"; // Redirect even if no referral code
    }
  };

  return (
    <div className="container">
      {/* App Icon */}
      <div className="app-icon">
        <img src={Logo} alt="App Icon" />
      </div>

      {/* App Title */}
      <h2 className="app-name">aroundU</h2>
      <h1 className="app-title">Get the App!</h1>

      {/* Copy Section */}
      <div className="copy-section">
        <p className="copy-text">Clicking below will copy this page</p>
        <div className="app-store-icon">
          <img src={FallbackImg} alt="App Store" />
        </div>
        <p className="redirect-text">
          You'll be redirected to this page in the app
        </p>
      </div>

      {/* Get the App Buttons */}
      <button className="get-app-button" onClick={handleCopyAndRedirect}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
        >
          <path
            fill="currentColor"
            d="M21 8.94a1.3 1.3 0 0 0-.06-.27v-.09a1 1 0 0 0-.19-.28l-6-6a1 1 0 0 0-.28-.19a.3.3 0 0 0-.09 0a.9.9 0 0 0-.33-.11H10a3 3 0 0 0-3 3v1H6a3 3 0 0 0-3 3v10a3 3 0 0 0 3 3h8a3 3 0 0 0 3-3v-1h1a3 3 0 0 0 3-3zm-6-3.53L17.59 8H16a1 1 0 0 1-1-1ZM15 19a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1V9a1 1 0 0 1 1-1h1v7a3 3 0 0 0 3 3h5Zm4-4a1 1 0 0 1-1 1h-8a1 1 0 0 1-1-1V5a1 1 0 0 1 1-1h3v3a3 3 0 0 0 3 3h3Z"
          />
        </svg>
        Get the App
      </button>
      <button
        className="get-app-link"
        onClick={() =>
          (window.location.href =
            "https://apps.apple.com/in/app/aroundu/id6470918157")
        }
      >
        Get the App without Copying
      </button>
    </div>
  );
};

export default IosFallback;
