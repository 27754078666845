import React from "react";
import Scroll2 from "./Scroll2";
import Download from "./Download";
import Service from "./Service";
import Footer from "./Footer";
import Navbar2 from "./Navbar2";

const Hero = () => {
    return (
      <>
      <Navbar2 />
      <Scroll2 />
      <Download />
      <Service />
      <Footer />
      </>
      
    );
  };
  
  export default Hero;